<template>
  <div
    @click.prevent="!disabled && $emit('switch', type)"
    :class="[
      'flex min-w-64 md:min-w-min mt-3 items-center p-4 shadow cursor-pointer transition-colors duration-200',
      isActive ? 'border-l-4 border-sky-500' : 'bg-white',
      disabled ? 'bg-gray-200 cursor-not-allowed text-gray-400' : 'hover:bg-blue-100',
    ]"
  >
    <img :src="`/images/icons/${image}`" alt="icon" class="lg:w-8 lg:h-8 md:w-4 md:h-4 w-8 h-8 mr-4"/>
    <div class="flex flex-col">
      <span class="text-lg font-semibold" :class="{ 'text-gray-400': disabled, 'text-gray-700': !disabled }">
        {{ name }}
      </span>
      <span v-if="disabled && type === 'flip'" class="text-sm text-gray-500">Od 1.3.2025</span>
      <span v-else-if="disabled" class="text-sm text-gray-500">Plánujeme</span>

    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryItem",
  props: {
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
/* Dodatečné úpravy stylů pokud potřeba */
</style>
