<template>
  <div class="web-modal-overlay" v-if="shouldShowModal">
    <div class="modal-content">
      <!-- Stepper Indicator -->
      <div class="stepper">
        <div :class="['step', currentStep >= 1 ? 'active' : '']">1. Výběr plánu</div>
        <div :class="['step', currentStep >= 2 ? 'active' : '']">2. Fakturační údaje</div>
        <div :class="['step', currentStep >= 3 ? 'active' : '']">3. Potvrzení</div>
      </div>

      <!-- Step 1: Plan Selection -->
      <div v-if="currentStep === 1">
        <div class="plan-selection">
          <div class="flex justify-center mb-6">
            <button :class="isMonthly ? 'bg-sky-500 text-white' : 'bg-sky-200 text-sky-700'"
                    class="w-1/2 px-6 py-2 rounded-l-lg" @click="switchToMonthly">Měsíční
            </button>
            <button :class="!isMonthly ? 'bg-sky-500 text-white' : 'bg-sky-200 text-sky-700'"
                    class="w-1/2 px-6 py-2 rounded-r-lg" @click="switchToYearly">Roční
            </button>
          </div>

          <h3 class="text-xl font-bold mb-4" v-if="!wasTrial">14 DNÍ ZDARMA, PAK</h3>

          <div class="mb-4">
            <p v-if="discountRate > 0" class="text-2xl font-semibold text-gray-500 line-through">
              {{ originalPrice }} Kč
            </p>
            <p :class="discountRate > 0 ? 'text-6xl font-bold text-red-600' : 'text-6xl font-bold'">
              {{ discountedPrice.toFixed(0) }} Kč
            </p>
          </div>

          <p class="text-gray-600 mb-6">{{ isMonthly ? 'měsíčně' : 'ročně' }}</p>

          <div class="mb-6" v-if="!wasTrial">
            <button v-if="!showDiscountInput" @click="showDiscountInput = true" class="text-sky-500 hover:underline">
              Uplatnit slevový kód
            </button>
            <div v-else class="flex flex-col">
              <label for="discountCode" class="text-gray-700 mb-2">Zadejte slevový kód:</label>
              <div class="flex">
                <input v-model="discountCode" id="discountCode" type="text"
                       class="w-full p-2 border rounded-l focus:outline-none focus:ring-2 focus:ring-sky-500"/>
                <button @click="applyDiscountCode" class="bg-sky-500 text-white px-4 rounded-r hover:bg-sky-600">
                  Uplatnit
                </button>
              </div>
              <p v-if="discountMessage" :class="{'text-green-600': discountSuccess, 'text-red-600': !discountSuccess}"
                 class="mt-2">
                {{ discountMessage }}
              </p>
            </div>
          </div>

          <button @click="goToStep(2)" class="primary-button">Pokračovat</button>
        </div>
      </div>

      <!-- Step 2: Billing Information -->
      <div v-if="currentStep === 2">
        <h2 class="text-2xl font-bold mb-4">Fakturační údaje</h2>
        <div class="space-y-4">
          <div>
            <label class="inline-flex items-center">
              <input type="checkbox" v-model="billingInfo.isCompany" class="form-checkbox">
              <span class="ml-2">Nakupuji na firmu</span>
            </label>
          </div>
          <div v-if="billingInfo.isCompany" class="space-y-4">
            <p class="font-bold text-center"> Prodáváme pouze firmám sídlícím v ČR!</p>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label for="companyName" class="block text-gray-700">Název firmy*:</label>
                <input v-model="billingInfo.companyName" id="companyName" type="text"
                       class="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-sky-500"/>
                <p v-if="errors.companyName" class="text-red-600 text-sm">{{ errors.companyName }}</p>
              </div>
              <div>
                <label for="ico" class="block text-gray-700">IČO*:</label>
                <input v-model="billingInfo.ico" id="ico" type="text"
                       class="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-sky-500"/>
                <p v-if="errors.ico" class="text-red-600 text-sm">{{ errors.ico }}</p>
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label for="dic" class="block text-gray-700">DIČ:</label>
                <input v-model="billingInfo.dic" id="dic" type="text"
                       class="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-sky-500"/>
              </div>
              <div>
                <label for="street" class="block text-gray-700">Ulice a č.p.*:</label>
                <input v-model="billingInfo.street" id="street" type="text"
                       class="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-sky-500"/>
                <p v-if="errors.street" class="text-red-600 text-sm">{{ errors.street }}</p>
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label for="firstName" class="block text-gray-700">Jméno:</label>
                <input v-model="billingInfo.firstName" id="firstName" type="text"
                       class="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-sky-500"/>
              </div>
              <div>
                <label for="lastName" class="block text-gray-700">Příjmení:</label>
                <input v-model="billingInfo.lastName" id="lastName" type="text"
                       class="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-sky-500"/>
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label for="city" class="block text-gray-700">Město*:</label>
                <input v-model="billingInfo.city" id="city" type="text"
                       class="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-sky-500"/>
                <p v-if="errors.city" class="text-red-600 text-sm">{{ errors.city }}</p>
              </div>
              <div>
                <label for="zip" class="block text-gray-700">PSČ*:</label>
                <input v-model="billingInfo.zip" id="zip" type="text"
                       class="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-sky-500"/>
                <p v-if="errors.zip" class="text-red-600 text-sm">{{ errors.zip }}</p>
              </div>
            </div>

            <div>
              <label class="block text-gray-700">Stát*:</label>
              <input type="text" value="Česká republika" disabled
                     class="w-full p-2 border rounded bg-gray-100"/>
            </div>
          </div>

          <div v-else class="space-y-4">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label for="firstName" class="block text-gray-700">Jméno*:</label>
                <input v-model="billingInfo.firstName" id="firstName" type="text"
                       class="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-sky-500"/>
                <p v-if="errors.firstName" class="text-red-600 text-sm">{{ errors.firstName }}</p>
              </div>
              <div>
                <label for="lastName" class="block text-gray-700">Příjmení*:</label>
                <input v-model="billingInfo.lastName" id="lastName" type="text"
                       class="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-sky-500"/>
                <p v-if="errors.lastName" class="text-red-600 text-sm">{{ errors.lastName }}</p>
              </div>
            </div>

            <div>
              <label class="block text-gray-700">Stát*:</label>
              <input type="text" value="Česká republika" disabled
                     class="w-full p-2 border rounded bg-gray-100"/>
            </div>
          </div>

        </div>
        <button @click="validateStep2" class="primary-button mt-6">Pokračovat</button>
        <button @click="goToStep(1)" class="secondary-button mt-2">Zpět</button>
      </div>

      <!-- Step 3: Confirmation -->
      <div v-if="currentStep === 3">
        <h2 class="text-2xl font-bold mb-4">Potvrzení předplatného</h2>
        <p class="mb-4">Před dokončením objednávky si prosím přečtěte následující informace:</p>
        <div class="info-box">
          <p class="text-gray-700 mb-4 text-sm">
            Zakládáte opakovanou platbu pro předplatné služby Rilo. Nyní Vám nebude účtován žádný poplatek, pouze
            ověříme Vaše platební údaje platbou ve výši <strong> 1 Kč</strong>, kterou Vám vrátíme. V případě, že
            předplatné nezrušíte do 14 dnů, bude Vám automaticky každých
            {{ isMonthly ? '30 dní (měsíčně)' : '365 dní (ročně)' }}
            strhávána částka <strong>{{ discountedPrice.toFixed(0) }} Kč</strong>. Maximální částka opakované platby je
            <strong>{{ originalPrice }} Kč {{ isMonthly ? 'měsíčně' : 'ročně' }}</strong>.
            <br><strong>Předplatné můžete kdykoliv
            zrušit v nastavení Vašeho účtu nebo nás kontaktujte na <a href="mailto:podpora@rilo.cz"
                                                                      class="text-sky-500 hover:underline">podpora@rilo.cz</a>.
          </strong>
          </p>
        </div>

        <div class="mb-4">
          <label class="flex items-start">
            <input type="checkbox" v-model="agreedToTerms" class="mt-1 mr-2">
            <span>Souhlasím s <a target="_blank" href="https://rilo.cz/https://rilo.cz/vseobecne-obchodni-podminky"
                                 class="text-sky-500 hover:underline">všeobecnými obchodními podmínkami</a>.</span>
          </label>
        </div>

        <div class="mb-4">
          <label class="flex items-start">
            <input type="checkbox" v-model="agreedToRecurringPayments" class="mt-1 mr-2">
            <span>Souhlasím s <a target="_blank" href="https://rilo.cz/podminky-opakovanych-plateb.html"
                                 class="text-sky-500 hover:underline">podmínkami opakovaných plateb</a> a s uložením platebních údajů u GoPay, která s nimi nakládá dle standardu PCI-DSS Level 1.</span>
          </label>
        </div>

        <button
          @click.prevent="startSubscription"
          :class="['primary-button', {'disabled': !agreedToTerms || !agreedToRecurringPayments ||  subscriptionLoading}]"
          :disabled="!agreedToTerms || subscriptionLoading || !agreedToRecurringPayments "
        >
          <template v-if="subscriptionLoading">
            <font-awesome-icon icon="spinner" spin class="mr-2"/>
            Počkejte prosím...
          </template>
          <template v-else>
            <template v-if="wasTrial">Znovu předplatit za {{ discountedPrice }} Kč</template>
            <template v-else>Dokončit objednávku</template>
          </template>
        </button>

        <button @click="goToStep(2)" class="secondary-button mt-2">Zpět</button>
      </div>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/pinia/userStore";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "ModalImportant",
  components: {FontAwesomeIcon},
  data() {
    return {
      isMonthly: true,
      SubscriptionPaid: false,
      SubscriptionNotPaid: false,
      shouldShowModal: false,
      CallForSubscriptionActivation: false,
      showDiscountInput: false,
      discountCode: '',
      discountUuid: null,
      discountRate: 0,
      discountMessage: '',
      discountSuccess: false,
      subscriptionLoading: false,
      agreedToTerms: false,
      agreedToRecurringPayments: false,
      currentStep: 1,
      billingInfo: {
        isCompany: false,
        companyName: '',
        ico: '',
        dic: '',
        firstName: '',
        lastName: '',
        street: '',
        city: '',
        zip: '',
      },
      errors: {},
    };
  },
  mounted() {
    this.checkSubscriptionStatus();
  },
  computed: {
    wasTrial() {
      const user = useUserStore().user;
      return user.has_used_trial;
    },
    subscriptionType() {
      return this.isMonthly ? "monthly" : "yearly";
    },
    originalPrice() {
      return this.isMonthly ? 195 : 1950;
    },
    discountedPrice() {
      return this.originalPrice - (this.originalPrice * (this.discountRate / 100));
    },
    formattedPrice() {
      return `${this.discountedPrice.toFixed(0)} Kč`;
    },
    discountedPriceText() {
      return `${this.discountedPrice.toFixed(0)} Kč`;
    },
  },
  methods: {
    switchToMonthly() {
      this.isMonthly = true;
    },
    switchToYearly() {
      this.isMonthly = false;
    },
    async applyDiscountCode() {
      if (!this.discountCode) {
        this.discountMessage = 'Prosím zadejte slevový kód.';
        this.discountSuccess = false;
        return;
      }

      try {
        const response = await this.$axios.post('/discount/verify', {code: this.discountCode});
        this.discountUuid = response.data.uuid;
        this.discountRate = parseFloat(response.data.discount_rate);
        this.discountMessage = response.data.message;
        this.discountSuccess = true;
      } catch (error) {
        console.error('Error verifying discount code:', error);
        this.discountUuid = null;
        this.discountRate = 0;
        if (error.response && error.response.data && error.response.data.message) {
          this.discountMessage = error.response.data.message;
        } else {
          this.discountMessage = 'Chyba při ověřování slevového kódu.';
        }
        this.discountSuccess = false;
      }
    },
    async startSubscription() {
      if (!this.agreedToTerms || this.subscriptionLoading || !this.agreedToRecurringPayments) {
        return;
      }
      this.subscriptionLoading = true;
      try {
        const requestData = {
          subscription_type: this.subscriptionType,
          billing_info: this.billingInfo,
        };

        if (this.discountUuid) {
          requestData.discount_code_uuid = this.discountUuid;
        }

        const response = await this.$axios.post('/gopay/verify-card', requestData);
        window.location.href = response.data.gw_url;
      } catch (error) {
        console.error('Error creating subscription:', error);
      } finally {
        this.subscriptionLoading = false;
      }
    },
    async checkSubscriptionStatus() {
      const urlParams = new URLSearchParams(window.location.search);
      const subscriptionPaid = urlParams.has("subscriptionPaid");
      const subscriptionError = urlParams.has("subscriptionError");
      const store = useUserStore();
      const userHasActiveSubscription = store.user.active_subscription;
      if (subscriptionPaid) {
        this.SubscriptionPaid = true;
        this.shouldShowModal = true;
      } else if (subscriptionError) {
        this.SubscriptionNotPaid = true;
        this.shouldShowModal = true;
      } else if (!userHasActiveSubscription) {
        this.CallForSubscriptionActivation = true;
        this.shouldShowModal = true;
      }
    },
    goToStep(step) {
      this.currentStep = step;
    },
    validateStep2() {
      this.errors = {};
      if (this.billingInfo.isCompany) {
        if (!this.billingInfo.companyName) {
          this.errors.companyName = 'Název firmy je povinný.';
        }
        if (!this.billingInfo.ico) {
          this.errors.ico = 'IČO je povinné.';
        } else if (!/^\d{8}$/.test(this.billingInfo.ico)) {
          this.errors.ico = 'IČO není platné!.';
        }

        if (!this.billingInfo.street) {
          this.errors.street = 'Ulice a č.p. je povinné.';
        }
        if (!this.billingInfo.city) {
          this.errors.city = 'Město je povinné.';
        }
        if (!this.billingInfo.zip) {
          this.errors.zip = 'PSČ je povinné.';
        }
      } else {
        if (!this.billingInfo.firstName) {
          this.errors.firstName = 'Jméno je povinné.';
        }
        if (!this.billingInfo.lastName) {
          this.errors.lastName = 'Příjmení je povinné.';
        }
      }

      if (Object.keys(this.errors).length === 0) {
        this.goToStep(3);
      }
    },
    validEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
      return re.test(email);
    },
  },
};
</script>

<style scoped>
.web-modal-overlay {
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.43);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 600px;
  width: 100%;
  max-height: 100vh;
  overflow-y: scroll;
}

.stepper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.step {
  flex: 1;
  text-align: center;
  padding: 0.5rem;
  border-bottom: 2px solid #e5e7eb;
}

.step.active {
  border-bottom: 2px solid #38bdf8;
  font-weight: bold;
}

.primary-button {
  background-color: #38bdf8;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.primary-button:hover {
  background-color: #0ea5e9;
}

.primary-button.disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.secondary-button {
  background-color: #e5e7eb;
  color: #374151;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.secondary-button:hover {
  background-color: #d1d5db;
}

.info-box {
  background-color: #f9fafb;
  padding: 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.list-disc li {
  margin-bottom: 0.5rem;
}
</style>
