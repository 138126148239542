<template>
  <div class="bg-amber-300 text-center p-1">
    Berte prosím na vědomí, že aplikace je prozatím v BETA testování. V případě, že narazíte na chybu nahlašte ji
    <a href="https://docs.google.com/forms/d/15TBlxCyBl5GTTYpo8_GhKHRyxtg1lMcQt_bKT4HEsiI/preview" class="underline">
      prosím zde! </a>
  </div>
  <MenuTemplate @openAiModal="openAi(true)"/>
  <FlashMessage id="flash-message"/>
  <div class="bg-gray-100" style="min-height: calc(100vh -  52px)">
    <div class="max-w-screen-xl m-auto">
      <router-view v-slot="{ Component, route }" @openSignModal="signPostOpen">
        <transition
          :name="route.meta.transition || 'fade'"
          mode="out-in"
        >
          <component :is="Component"/>
        </transition>
      </router-view>
    </div>
  </div>

  <rilo-intelligence v-if="isRiloIntelligenceOpen" @closeChat="openAi(false)"/>

  <guide-template v-else-if="isLoaded && user && !user.completed_guide && user.active_subscription"/>

  <modal-important v-else-if="isLoaded"/>
  <FooterTemplate/>
</template>

<script>
import MenuTemplate from '@/components/MenuTemplate.vue';
import FlashMessage from '@/components/share/FlashMessage.vue';
import RiloIntelligence from '@/pages/Ai/RiloIntelligence.vue';
import ModalImportant from '@/components/ModalImportant.vue';
import {useUserStore} from '@/pinia/userStore';
import GuideTemplate from '@/components/guide/GuideTemplate.vue';
import FooterTemplate from "@/components/FooterTemplate.vue";

export default {
  name: 'AppPage',

  components: {
    FooterTemplate,
    GuideTemplate,
    ModalImportant,
    RiloIntelligence,
    FlashMessage,
    MenuTemplate,
  },

  async mounted() {
    const userStore = useUserStore();
    await userStore.fetchUserCredits();
    await userStore.fetchUserData();
    this.isLoaded = true;
  },

  data() {
    return {
      signpostModal: false,
      isRiloIntelligenceOpen: false,
      isLoaded: false,
    };
  },

  computed: {
    user() {
      return useUserStore().user;
    },
  },

  methods: {
    signPostOpen() {
      this.signpostModal = true;
    },

    openAi(condition) {
      this.isRiloIntelligenceOpen = condition;
    },

    notify(type, title, message) {
      this.$refs.notification.showNotification({type, title, message});
    },
  },
};
</script>

<style>
.slide-left-enter-active {
  animation: slideInFromRight 0.3s ease-out forwards;
}

.slide-left-leave-active {
  animation: slideOutToLeft 0.3s ease-in forwards;
}

.slide-right-enter-active {
  animation: slideInFromLeft 0.3s ease-out forwards;
}

.slide-right-leave-active {
  animation: slideOutToRight 0.3s ease-in forwards;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%);
    opacity: 0;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50%);
    opacity: 0;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
